<template>
  <div class="allianceInfo">
    <div class="flex justify-between items-center" v-if="type == 'applyBox'">
      <div>
        <SubTitle>联盟信息</SubTitle>
        <div class="content">
          <span class="mr-16">联盟名称：{{ dataObj.name }}</span>
          <span class="mr-16">所在地区：{{ dataObj.area }}</span>
          <span> 负责人：{{ dataObj.contacts }}（{{ dataObj.phone }}） </span>
        </div>
      </div>
      <div class="logo">
        <img v-if="dataObj.logoUrl" :src="dataObj.logoUrl" alt="联盟图标" />
        <span v-else>联盟图标</span>
      </div>
    </div>

    <div v-if="type == 'norm'">
      <SubTitle>联盟简介</SubTitle>
      <div class="content">
        {{ dataObj.introduction }}
      </div>
    </div>

    <div class="flex-1">
      <SubTitle>联盟规则</SubTitle>
      <div class="content border rules">
        <div class="w-e-text" v-html="dataObj.institution"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SubTitle from "@/components/manageCom/SubTitle.vue";
export default {
  name: "AllianceInfo", //联盟规范信息
  components: {
    SubTitle,
  },
  props: {
    type: {
      type: String,
    },
    allianceInfo: {
      type: Object,
    },
  },
  data() {
    return {
      // dataObj: {
      //   name: "成都汽车配件合作大联盟",
      //   area: "四川省/成都市/武侯区",
      //   contacts: "吴忠辉",
      //   phone: "17770479608",
      //   logoUrl: require("@/assets/logo.png"),
      //   introduction:
      //     "联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介联盟简介",
      //   institution:
      //     "<h2>联盟管理制度</h2><p>&nbsp; &nbsp; &nbsp; 为了充分发挥联盟各成员相互之间合作的优势，促进联盟汽配集约化、网络化经营，提升联盟配件企业经营水平。根据国家有关法律、法规，经过相互平等友好协商，共同就结成联盟形成以下联盟管理制度：<br/></p><p>第一条&nbsp;&nbsp; &nbsp;&nbsp;联盟内共享库存在满足成员需求,并价格合适情况下，成员应在联盟内采购。</p><p>第二条&nbsp;&nbsp; &nbsp;&nbsp;平台价格为成员间交易上限，不得以缺货涨价等原因拒绝销售。</p><p>第三条&nbsp;&nbsp; &nbsp;&nbsp;平台经营配件不得以次充好，知假买假。如确实因采购时不慎出现货物假冒，提供进货凭证可酌情处理。</p><p>第四条&nbsp;&nbsp; &nbsp;&nbsp;平台默认每月15日为结帐期，超期不结账成员可以向管委会投诉。说明情况可适当延期并确认结算时间，再次逾期则给予处罚。</p><p>第五条&nbsp;&nbsp; &nbsp;&nbsp;平台成员上传库存需准确，不得以库存有误拒绝销售。</p><p>第六条&nbsp;&nbsp; &nbsp;&nbsp;一个月内成员间退货在无损不影响再次销售的情况下友好协商处理。</p><p>第七条&nbsp;&nbsp; &nbsp;&nbsp;因市场价格变动，成员应主动向管委会通报。</p><p>第八条&nbsp;&nbsp; &nbsp;&nbsp;成员不得泄露联盟库存及价格信息，一经发现罚款5000元，二次发生开除出联盟。</p><p>第九条&nbsp;&nbsp; &nbsp;&nbsp;联盟会议及集体活动提前通知，可提前请假。</p><p>第十条&nbsp;&nbsp; &nbsp;&nbsp;成员有义务对不良商户在联盟内通报。</p><p>成员违反上述制度者，以管委会处理决定为准，管委会拥有最终解释权。</p>",
      // },
    };
  },
  computed: {
    dataObj() {
      return this.allianceInfo;
    },
  },
};
</script>

<style lang="less" scoped>
.allianceInfo {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    margin: 10px 20px;
  }
  .w-e-text {
    /deep/h1,
    /deep/h2,
    /deep/h3,
    /deep/h4,
    /deep/h5,
    /deep/h6 {
      font-size: revert !important;
      font-weight: revert !important;
    }
  }
  .rules {
    padding: 10px;
  }
  .logo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
