<template>
  <div class="alliancePromotion bg-white flex flex-col p-16 ">
    <div class="img flex items-center justify-center">
      <img :src="imgUrl" alt="" />
      <span v-if="!imgUrl">宣传图</span>
    </div>
    <Search
      class="mt-8"
      :formData="formData"
      placeholderText="联盟名称/公司名称/联系人/联系电话"
      @handleQuery="handleQuery"
    >
      <template slot="left">
        <SelectArea
          checkStrictly
          size="small"
          class="area"
          placeholder="地区筛选"
          @change="areaChange"
        />
      </template>
      <template slot="right">
        <el-button type="primary" size="small" @click="createAlliance">
          创建联盟
        </el-button>
      </template>
    </Search>

    <el-table
      ref="table"
      class="table-base mt-8"
      size="small"
      :data="tableData"
      stripe
      border
      :default-sort="{ prop: 'createdAt', order: 'descending' }"
      style="width: 100%;"
      @sort-change="sortChange"
    >
      <el-table-column
        label="联盟名称"
        prop="name"
        min-width="150"
        align="center"
      >
        <template slot-scope="scope">
          <div class="name_box">
            <div>{{ scope.row.name }}</div>
            <div class="create_box" v-if="scope.row.isOwner">
              <svg
                xmlns:xlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                width="37px"
                height="39px"
              >
                <g transform="matrix(1 0 0 1 -218 -129 )">
                  <path
                    d="M 19.2594625500287 20.3161234991423  L 0 0  L 37 0  L 37 39  L 19.2594625500287 20.3161234991423  Z "
                    fill-rule="nonzero"
                    fill="#f48305"
                    stroke="none"
                    transform="matrix(1 0 0 1 218 129 )"
                  />
                </g>
              </svg>
              <span class="create">创建</span>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="联盟简介"
        prop="introduction"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip
            :enterable="false"
            effect="dark"
            :content="scope.row.introduction"
            placement="top"
          >
            <div class="text-show-two">{{ scope.row.introduction }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        label="所在地区"
        prop="area"
        min-width="100"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="盟主"
        prop="ownerName"
        min-width="120"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div class="text-show-one">
            {{ scope.row.ownerName }}
          </div>
          <div>{{ scope.row.contacts }}/{{ scope.row.phone }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="成员数"
        prop="memberCount"
        min-width="60"
        align="center"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        label="创建日期"
        prop="createdAt"
        min-width="100"
        align="center"
        sortable
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column fixed="right" label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="mini"
            :disabled="scope.row.joined"
            @click="handleJoin(scope.row)"
          >
            申请加入
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      class="table-bottom"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      background
      :current-page="pageData.page"
      :page-sizes="pageData.pageSizes"
      :page-size="pageData.pageSize"
      :pager-count="pageData.pagerCount"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageData.total"
    >
    </el-pagination>
    <!-- 申请加入弹框 -->
    <ApplyJoinBox
      ref="applyJoin"
      :allianceId="allianceId"
      @refresh="refreshList"
    />
  </div>
</template>

<script>
import Search from "@/views/alliance/components/Search.vue";
import SelectArea from "@/components/selectArea/SelectArea.vue";
import ApplyJoinBox from "@/views/alliance/components/ApplyJoinBox.vue";
import {
  getAllianceList, //所有联盟列表
} from "@/api/alliance";

const defaultFormData = {
  keyword: "", // 关键字
  areaId: 0,
};
export default {
  name: "AlliancePromotion",
  components: {
    Search,
    SelectArea,
    ApplyJoinBox,
  },
  data() {
    return {
      imgUrl: require("@/assets/img/adMap/alliance2.png"),
      loading: false,
      formData: JSON.parse(JSON.stringify(defaultFormData)),
      tableData: [],
      pageData: {
        page: 1,
        pageSizes: [10, 20, 50, 100],
        pageSize: 10,
        total: 0,
        pagerCount: 7,
      },
      sort: "createdAt-desc",
      allianceId: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    refreshList() {
      this.getList();
    },
    //申请加入
    handleJoin(row) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.allianceId = row.id;
      this.$refs.applyJoin.show();
    },
    handleQuery() {
      this.pageData.page = 1;
      this.getList();
    },
    areaChange(areaObj) {
      this.formData.areaId = areaObj.areaId;
      this.handleQuery();
    },
    sortChange(sort) {
      if (!sort.order) return;
      let order = "";
      if (sort.order === "descending") {
        order = "desc";
      }
      if (sort.order === "ascending") {
        order = "asc";
      }
      this.sort = sort.prop + "-" + order;
      this.getList();
    },
    handleSizeChange(val) {
      this.pageData.page = 1;
      this.pageData.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageData.page = val;
      this.getList();
    },
    getList() {
      this.loading = true;
      const params = {
        ...this.formData,
        page: this.pageData.page,
        pageSize: this.pageData.pageSize,
        sort: this.sort,
      };
      getAllianceList(params).then((res) => {
        this.tableData = res?.rows || [];
        this.pageData.total = res?.totalSize || 0;
        this.loading = false;
      });
    },
    //创建联盟
    createAlliance() {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.$router.push("/allianceManage/create");
    },
  },
};
</script>

<style lang="less" scoped>
.alliancePromotion {
  .img {
    height: 136px;
    background: #d7d7d7;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .area {
    width: 200px !important;
  }
  /deep/.table-base .el-table__row td:first-child .cell {
    height: 100%;
  }
  .name_box {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .create_box {
      position: absolute;
      right: -26px;
      top: -7px;
      width: 52px;
      .create {
        position: absolute;
        top: 0;
        right: 15px;
        font-size: 10px;
        color: #ffffff;
      }
    }
  }
  .table-bottom {
    text-align: right;
    margin-top: @margin-size-secondary;
  }
}
</style>
